import React, { Fragment } from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { compose, mapProps, withProps } from 'recompose';
import { isDefinedAndNotNull } from '../../../../se/utilities/check';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';
import compact from 'lodash/compact';
import DevSvg from '../../../../assets/images/icons/developer_mode.svg';
import Footer from './Footer';
import Header from './Header';
import ChangePassword from '../ChangePassword';
import { dark as darkTheme, light as lightTheme } from '../../../../theme';
import ThemeBasedCSS from '../../../../components/ThemeBasedCSS';
import Patients from '../../../entities/patient/ActiveAndPastPatients';
import { unpackSessionObject } from '../../unpackSessionObject';
import { withSession } from '../../../../state/Session';
import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import get from 'lodash/fp/get';
import { muiThemeLight } from '../../../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import Training from '../../training/Training';
import { CssBaseline } from '@material-ui/core';
import { MainContainer } from '../../MainContainer';
import { OmniSearchProvider, OmniSearchSources } from '../../../OmniSearch';
import activeAndPastProceduresSource from '../../../../omnisearch/sources/hospital/activeAndPastProcedures';
import scheduledProceduresSource from '../../../../omnisearch/sources/hospital/scheduledProcedures';
import { AccessibilityNew, Event, Theaters, Tv, VideoLabel } from '@material-ui/icons';
import { withScope } from '../../../../contexts/ScopeContext';

const getSystemScreenRoutes = (basePath, baseUrl, organizationId) =>
  compact([
    {
      path: `/kiosk/${organizationId}/monitor`,
      to: `/kiosk/${organizationId}/monitor`,
      target: '_blank',
      label: 'Operations Screen',
      icon: <VideoLabel />,
    },
    {
      path: `/kiosk/${organizationId}/schedule`,
      to: `/kiosk/${organizationId}/schedule`,
      label: 'OR Schedule',
      icon: <Event />,
    },
    {
      path: `/kiosk/${organizationId}/waiting-room`,
      to: `/kiosk/${organizationId}/waiting-room`,
      target: '_blank',
      label: 'Waiting Room Screen',
      icon: <Tv />,
      hideOnMobile: true,
    },
  ]);

const getRoutes = (basePath, baseUrl) =>
  [
    {
      separator: true,
    },
    {
      path: `${basePath}/patients`,
      to: `${baseUrl}/patients`,
      label: 'Patients',
      icon: <AccessibilityNew />,
      component: Patients,
    },
    {
      separator: true,
    },
  ].filter(_ => !!_);

const getBasePathAndUrl = match => {
  if (!isDefinedAndNotNull(match) || !isDefinedAndNotNull(!match.path) || !isDefinedAndNotNull(!match.url)) {
    return ['', ''];
  }
  return [match.path === '/' ? '' : match.path, match.url === '/' ? '' : match.url];
};

const AdminSidebar = compose(
  withSession(unpackSessionObject),
  withRouter,
  withScope,
  withProps({
    Header,
    links: [],
    Footer,
  }),
  mapProps(props => {
    const { organization, match, search } = props;
    const organizationId = organization || match.params.organizationId;
    const [basePath, baseUrl] = getBasePathAndUrl(match);

    return {
      ...props,
      links: compact([
        ...getSystemScreenRoutes(
          basePath,
          baseUrl,
          organizationId,
          search,
          Object.assign(
            {},
            {
              covidScreening: !!props.scope?.hospital?.covidScreening,
            },
            get('scope.hospital.modules')(props)
          )
        ),
        ...getRoutes(
          basePath,
          baseUrl,
          Object.assign(
            {},
            {
              covidScreening: !!props.scope?.hospital?.covidScreening,
              ospitekOrdersIntegrations: !!props.scope?.hospital?.ospitekOrdersIntegrations?.externalLink?.enabled,
            },
            get('scope.hospital.modules')(props)
          ),
          organizationId,
          props.scope?.hospital?.ospitekOrdersIntegrations
        ),
      ]),
    };
  })
)(Sidebar);

const Homepage = props => {
  const [basePath, baseUrl] = getBasePathAndUrl(props.match);
  const modules = {
    patientRegistration: true,
    temperatureReporting: true,
    acceptsProcedureTransfer: true,
    covidScreening: Boolean(props.scope?.hospital?.covidScreening),
    inTakeForms: Boolean(props.scope?.hospital?.modules?.inTakeForms),
  };
  const switchRoutes = compact(getRoutes(basePath, baseUrl, modules)).filter(_ => !_.separator);
  return (
    <ThemeProvider theme={lightTheme}>
      <MUIThemeProvider theme={muiThemeLight}>
        <style>
          {`body {
            background: ${muiThemeLight.palette.background.default};
          }`}
        </style>
        <CssBaseline />
        <PatientNameFormatContext.Provider value={props.patientNameFormat || 'FullName'}>
          <OmniSearchProvider>
            <OmniSearchSources sources={[activeAndPastProceduresSource, scheduledProceduresSource]} />
            <Fragment>
              <Page Sidebar={AdminSidebar}>
                <MainContainer switchControl>
                  <Switch>
                    <Route exact path={`${basePath}/`} render={() => <Redirect to={`${baseUrl}/patients`} />} />
                    {switchRoutes.map(({ path, component, subPages }, i) => [
                      path ? <Route key={i} path={path} component={component} /> : null,
                      ...(subPages
                        ? subPages.map(subPage => (
                            <Route
                              key={path ? `${path}${subPage.path}` : subPage.path}
                              path={path ? `${path}${subPage.path}` : subPage.path}
                              component={subPage.component}
                            />
                          ))
                        : []),
                    ])}
                    <Route path="/change-password" component={ChangePassword} />
                  </Switch>
                </MainContainer>
              </Page>
              <ThemeBasedCSS />
            </Fragment>
          </OmniSearchProvider>
        </PatientNameFormatContext.Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export default compose(
  withSession(unpackSessionObject),
  withRouter,
  withSession(get('session.account.metadata')),
  withScope
)(Homepage);
