import { workingHours } from '../../../../entities/schedule/util/dummyData';
import Box from '@material-ui/core/Box';
import React, { CSSProperties, FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const totalWorkingHours = workingHours.end - workingHours.start;

// so overlay would not go under procedure in edit mode
const REDUCE_OVERLAY = 0.5;

const TimeFrame: FC<{
  style?: CSSProperties;
  from: number;
  to: number;
  onClick: () => void;
}> = ({ style, from, to, onClick, children }) => {
  const classes = useStyles();
  const top = ((from - workingHours.start) / totalWorkingHours) * 100;
  const height = ((to - from) / totalWorkingHours) * 100;

  console.debug(
    'workingHours',
    workingHours,
    'totalWorkingHours',
    totalWorkingHours,
    'from',
    from,
    'to',
    to,
    'top',
    top,
    'height',
    height
  );

  return (
    <Box
      display="flex"
      position="absolute"
      width="100%"
      top={`${top}%`}
      height={`${height}%`} // so it
      className={classes.slot}
      style={style}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    slot: {
      cursor: 'pointer',
      '&:hover': {
        filter: 'brightness(150%)',
      },
    },
  })
);

export default TimeFrame;
