import React, { createElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Value } from './ValueContext';
import Page1RF from './riverFront/pages/Page1';
import Page3RF from './riverFront/pages/Page3';
import Page4RF from './riverFront/pages/Page4';
import Page5RF from './riverFront/pages/Page5';
import Page6RF from './riverFront/pages/Page6';
import Page7RF from './riverFront/pages/Page7';
import Page8RF from './riverFront/pages/Page8';
import Page1UO from './uascOutpatient/pages/Page1';
import Page3UO from './uascOutpatient/pages/Page3';
import Page4UO from './uascOutpatient/pages/Page4';
import Page5UO from './uascOutpatient/pages/Page5';
import Page6UO from './uascOutpatient/pages/Page6';
import Page7UO from './uascOutpatient/pages/Page7';
import Page8UO from './uascOutpatient/pages/Page8';
import Page1nGC from './golfCoast/pages/nPage1';
import Page2nGC from './golfCoast/pages/nPage2';
import Page3nGC from './golfCoast/pages/nPage3';
import Page4nGC from './golfCoast/pages/nPage4';
import Page5nGC from './golfCoast/pages/nPage5';
import Page6nGC from './golfCoast/pages/nPage6';
import Page7nGC from './golfCoast/pages/nPage7';
import Page8nGC from './golfCoast/pages/nPage8';
import Page9nGC from './golfCoast/pages/nPage9';
import Page10nGC from './golfCoast/pages/nPage10';
import Page11nGC from './golfCoast/pages/nPage11';
import additionalMedication from './golfCoast/pages/nPage11_1';
import Page12nGC from './golfCoast/pages/nPage12';
import Page13nGC from './golfCoast/pages/nPage13';
import Page14nGC from './golfCoast/pages/nPage14';
import Page1GT from './goldenTriangle/pages/Page1';
import Page3GT from './goldenTriangle/pages/Page3';
import Page5GT from './goldenTriangle/pages/Page5';
import Page7GT from './goldenTriangle/pages/Page7';
import Page8GT from './goldenTriangle/pages/Page8';
import sampleGT5 from './goldenTriangle/pages/sample_page5';
import sampleGT8 from './goldenTriangle/pages/sample_page8';
import Page1GTOld from './goldenTriangleOld/pages/Page1';
import Page2GTOld from './goldenTriangleOld/pages/Page2';
import Page3GTOld from './goldenTriangleOld/pages/Page3';
import Page4GTOld from './goldenTriangleOld/pages/Page4';
import Page5GTOld from './goldenTriangleOld/pages/Page5';
import Page1Ontario from './ontario/Page1';
import Page3Ontario from './ontario/Page3';
import Page4Ontario from './ontario/Page4';
import Page5Ontario from './ontario/Page5';
import Page7Ontario from './ontario/Page7';
import Page8Ontario from './ontario/Page8';
import Page1V3 from './v3/pages/Page1';
import Page3V3 from './v3/pages/Page3';
import Page4V3 from './v3/pages/Page4';
import Page5V3 from './v3/pages/Page5';
import Page6V3 from './v3/pages/Page6';
import Page7V3 from './v3/pages/Page7';
import Page1V2 from './v2/pages/Page1';
import Page3V2 from './v2/pages/Page3';
import Page4V2 from './v2/pages/Page4';
import Page5V2 from './v2/pages/Page5';
import Page6V2 from './v2/pages/Page6';
import Page7V2 from './v2/pages/Page7';
import Page1V1 from './v1/pages/Page1';
import Page3V1 from './v1/pages/Page3';
import Page4V1 from './v1/pages/Page4';
import Page5V1 from './v1/pages/Page5';
import Page6V1 from './v1/pages/Page6';
import Page7V1 from './v1/pages/Page7';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import NoPermissionText from '../../../NoPermissionText';
import useHasAccessRight from '../../../../hooks/useHasAccessRight';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Page8V2 from './v2/pages/Page8';
import Page8V3 from './v3/pages/Page8';
import PolarisPage1 from './polaris/Page1';
import PolarisPage2 from './polaris/Page2';
import PolarisPage3 from './polaris/Page3';
import PolarisPage4 from './polaris/Page4';
import PolarisPage5 from './polaris/Page5';
import PolarisPage6 from './polaris/Page6';
import PolarisPage7 from './polaris/Page7';
import PolarisPage8 from './polaris/Page8';
import PolarisPage9 from './polaris/Page9';
import PolarisPage10 from './polaris/Page10';
import PolarisPage11 from './polaris/Page11';
import Polaris2Page1 from './polaris2/Page1';
import Polaris2Page2 from './polaris2/Page2';
import Polaris2Page3 from './polaris2/Page3';
import Polaris2Page4 from './polaris2/Page4';
import Polaris2Page5 from './polaris2/Page5';
import Polaris2Page6 from './polaris2/Page6';
import Polaris2Page7 from './polaris2/Page7';
import Polaris2Page8 from './polaris2/Page8';
import Polaris2Page9 from './polaris2/Page9';
import Polaris2Page10 from './polaris2/Page10';
import Polaris2Page11 from './polaris2/Page11';
import OCOMfallRiskAssessment from './fallRiskAssessment/OCOMfallRiskAssessment';
import fallRiskAssessment30 from './fallRiskAssessment/fallRiskAssessment30';
import fallRiskAssessmentV1 from './fallRiskAssessment/fallRiskAssessmentV1';
import fallRiskAssessmentV2 from './fallRiskAssessment/fallRiskAssessmentV2';
import fallRiskAssessmentV3 from './fallRiskAssessment/fallRiskAssessmentV3';
import fallRiskAssessmentCharting from './fallRiskAssessment/fallRiskAssessmentCharting';
import smithfallRiskAssessmentCharting from './fallRiskAssessment/smithfallRiskAssessmentCharting';
import AnesthesiaTableV1 from './anesthesiaCharting/AnesthesiaTableV1';
import AnesthesiaVitals from './anesthesiaCharting/AnesthesiaVitals';
import DictationForm from './anesthesiaCharting/DictationForm';
import { useTheme } from '@material-ui/core';
import AnesthesiaTableV2 from './anesthesiaCharting/AnesthesiaTableV2';

const ErrorPage = () => (
  <div
    className="page"
    style={{
      display: 'grid',
      alignContent: 'center',
      justifyItems: 'center',
    }}
  >
    <h2>Unable to render this page.</h2>
  </div>
);

const PredefinedForm = renderer => {
  const Component = ({
    hospitalId,
    formName,
    hospitalName,
    defaultValue,
    value,
    setValue,
    signature,
    questionnaire,
    questionnaireAnswers,
    lastPreOpCompletedEvent,
    procedureId,
    showQRCode,
  }) => {
    const hasAccessRight = useHasAccessRight();
    const isAllowedToView = hasAccessRight('patient.view');

    return (
      <Box style={!isAllowedToView ? { position: 'relative' } : undefined}>
        {!isAllowedToView && (
          <Box
            style={{
              backgroundColor: 'white',
              color: theme.palette.background.default,
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 100,
            }}
          >
            <NoPermissionText withText={true} style={{ top: '50%', left: '25%' }} />
          </Box>
        )}
        <Value.Provider value={{ defaultValue, value, setValue, questionnaire, questionnaireAnswers }}>
          {createElement(
            renderer,
            {
              hospitalName,
              defaultValue,
              value,
              setValue,
              questionnaire,
              questionnaireAnswers,
              lastPreOpCompletedEvent,
              procedureId,
              showQRCode,
              formName: formName,
            },
            (page, i) => (
              <Value.Provider key={i} value={{ defaultValue, value, setValue, questionnaire, questionnaireAnswers }}>
                <ErrorBoundary FallbackComponent={ErrorPage}>{page}</ErrorBoundary>
              </Value.Provider>
            )
          )}
        </Value.Provider>
      </Box>
    );
  };

  Component.displayName = 'PredefinedForm';

  return Component;
};

const OldPredefinedForm = pages => {
  const Component = ({
    hospitalId,
    formName,
    hospitalName,
    defaultValue,
    value,
    setValue,
    signature,
    questionnaire,
    questionnaireAnswers,
    lastPreOpCompletedEvent,
    procedureId,
    showQRCode,
  }) => {
    const theme = useTheme();
    const hasAccessRight = useHasAccessRight();
    const isAllowedToView = hasAccessRight('patient.view');

    return (
      <Box style={!isAllowedToView ? { position: 'relative' } : undefined}>
        {!isAllowedToView && (
          <Box
            syle={{
              backgroundColor: 'white',
              color: theme.palette.background.default,
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 100,
            }}
          >
            <NoPermissionText withText={true} style={{ top: '50%', left: '25%' }} />
          </Box>
        )}
        <Value.Provider value={{ defaultValue, value, setValue, questionnaire, questionnaireAnswers }}>
          {pages.map((Page, i) => (
            <ErrorBoundary key={i} FallbackComponent={ErrorPage}>
              <Page
                {...{
                  hospitalName,
                  defaultValue,
                  value,
                  setValue,
                  questionnaire,
                  questionnaireAnswers,
                  lastPreOpCompletedEvent,
                  procedureId,
                  showQRCode,
                  formName: formName,
                  pageNumber: i + 1,
                }}
              />
            </ErrorBoundary>
          ))}
        </Value.Provider>
      </Box>
    );
  };

  Component.displayName = 'PredefinedForm';

  return Component;
};

const predefinedForms = {
  medicalPassport: OldPredefinedForm([Page1V1, Page3V1, Page4V1, Page5V1, Page6V1, Page7V1]),
  medicalPassport1: OldPredefinedForm([Page1V1, Page3V1, Page4V1, Page5V1, Page6V1, Page7V1]),
  medicalPassport2: OldPredefinedForm([Page1V2, Page3V2, Page4V2, Page5V2, Page6V2, Page7V2, Page8V2]),
  medicalPassport3: OldPredefinedForm([Page1V3, Page3V3, Page4V3, Page5V3, Page6V3, Page7V3, Page8V3]),
  medicalPassport7: OldPredefinedForm([
    PolarisPage1,
    PolarisPage2,
    PolarisPage3,
    PolarisPage5,
    PolarisPage6,
    PolarisPage7,
    PolarisPage8,
    PolarisPage9,
    PolarisPage10,
    PolarisPage11,
  ]),
  medicalPassport8: OldPredefinedForm([
    Polaris2Page1,
    Polaris2Page2,
    Polaris2Page3,
    Polaris2Page5,
    Polaris2Page6,
    Polaris2Page7,
    Polaris2Page8,
    Polaris2Page9,
    Polaris2Page10,
    Polaris2Page11,
  ]),
  medicalPassport22: OldPredefinedForm([Page1RF, Page3RF, Page4RF, Page5RF, Page6RF, Page7RF, Page8RF]),
  medicalPassport31: OldPredefinedForm([Page1UO, Page3UO, Page4UO, Page5UO, Page6UO, Page7UO, Page8UO]),
  medicalPassport45: OldPredefinedForm([Page1GTOld, Page2GTOld, Page3GTOld, Page4GTOld, Page5GTOld]),
  medicalPassport46: OldPredefinedForm([Page1GTOld, Page2GTOld, Page3GTOld, Page4GTOld, Page5GTOld]),
  medicalPassport47: OldPredefinedForm([Page1GT, Page3GT, Page5GT, Page7GT, Page8GT]),
  medicalPassport58: OldPredefinedForm([
    Page1Ontario,
    Page3Ontario,
    Page4Ontario,
    Page5Ontario,
    Page7Ontario,
    Page8Ontario,
  ]),
  medicalPassport55: OldPredefinedForm([
    Page1nGC,
    Page11nGC,
    additionalMedication,
    Page6nGC,
    Page3nGC,
    Page4nGC,
    Page5nGC,
    Page14nGC,
    Page7nGC,
    Page8nGC,
    Page9nGC,
    Page10nGC,
    Page12nGC,
    Page13nGC,
    Page2nGC,
  ]),
  medicalPassport56: OldPredefinedForm([
    Page1nGC,
    Page11nGC,
    additionalMedication,
    Page6nGC,
    Page3nGC,
    Page4nGC,
    Page5nGC,
    Page14nGC,
    Page7nGC,
    Page8nGC,
    Page9nGC,
    Page10nGC,
    Page12nGC,
    Page13nGC,
    Page2nGC,
  ]),
  golfYAGform: OldPredefinedForm([Page2nGC, Page11nGC, Page12nGC, Page13nGC]),
  fallRiskAssessment: OldPredefinedForm([fallRiskAssessmentV1]),
  fallRiskAssessment2: OldPredefinedForm([fallRiskAssessmentV2]),
  fallRiskAssessment3: OldPredefinedForm([fallRiskAssessmentV3]),
  fallRiskAssessment30: OldPredefinedForm([fallRiskAssessment30]),
  OCOMfallRiskAssessment: OldPredefinedForm([OCOMfallRiskAssessment]),
  fallRiskAssessmentCharting: OldPredefinedForm([fallRiskAssessmentCharting]),
  smithFallRiskAssessmentCharting: PredefinedForm(smithfallRiskAssessmentCharting),
  vteAssessment: OldPredefinedForm([Page6V2]),
  anesthesiaTable: PredefinedForm(AnesthesiaTableV1, true),
  anesthesiaTableV1: PredefinedForm(AnesthesiaTableV1, true),
  anesthesiaTableV2: PredefinedForm(AnesthesiaTableV2, true),
  anesthesiaVitals: PredefinedForm(AnesthesiaVitals, true),
  dictationForm: PredefinedForm(DictationForm, true),
};

export default predefinedForms;
