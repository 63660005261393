import React, { Fragment } from 'react';
import styled from 'styled-components';
import { getSurgeryInstructionsTemplates, updateSurgeryInstructionsTemplate } from '../../../graph/procedures';
import { withLabel } from '../../../se/components/Label';
import { CenteredSpinner } from '../../../se/components/Spinner';
import RichTextField from './RichTextField';
import { withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import Form from '../../../se/components/Form';
import Button from '../../../se/components/Button';
import LinkButton from '../../../se/components/LinkButton';
import { H3 } from '../../../se/components/typography';
import {
  instructionTemplateLabels,
  instructionTemplateLabelsView2Hospital15,
  instructionTemplateLabelsViewHospital2,
  instructionTemplateLabelsView2Hospital22,
  instructionTemplateLabelsViewHospital55,
  instructionTemplateLabelsView4OCOM,
  InstructionTemplates,
} from '../procedures/enums';
import { useMutation, useQuery } from '@apollo/client';
import { useScope } from '../../../hooks/useScope';

const helper = 'You can use standard shortcuts for text manipulation or undo / redo';
const TemplateInstructionsInput = withProps({
  schema: {
    content: withLabel('Default Instructions', {}, helper)(RichTextField),
  },
})(ObjectInput);

const InstructionsInput = withProps({
  schema: {
    content: withLabel('', {}, helper)(RichTextField),
  },
})(ObjectInput);

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Footer = ({ busy, isDirty, onCancel }: any) =>
  isDirty ? (
    <Actions>
      <Button busy={undefined} disabled={busy}>
        Save
      </Button>
      <LinkButton disabled={busy} onClick={onCancel}>
        Cancel
      </LinkButton>
    </Actions>
  ) : null;

export const InstructionsForm = ({
  value,
  mutation,
  refetchQueries,
  variables = {},
  isTemplate,
  disabled,
  templateType,
}: any) => {
  const [save, { loading }] = useMutation(mutation);
  return (
    <Form
      label={''}
      initialValue={{ content: value }}
      input={isTemplate ? TemplateInstructionsInput : InstructionsInput}
      onSubmit={async (value: any) => {
        await save({
          variables: {
            templateType: templateType,
            content: JSON.stringify({ content: value?.content }),
            ...variables,
          },
          refetchQueries,
        });
      }}
      withoutReset={true}
      busy={loading}
      Footer={Footer}
      disabled={disabled}
    />
  );
};

export default () => {
  const scope: any = useScope();
  const hospitalId = scope?.hospital?.id;

  const caseView2Hospital15 =
    (window.location.hostname === 'localhost' && hospitalId === 59) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 59) ||
    (window.location.hostname === 'view2.ospitek.com' && hospitalId === 15);

  const caseViewHospital2 =
    (window.location.hostname === 'localhost' && hospitalId === 67) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 67) ||
    (window.location.hostname === 'view.ospitek.com' && hospitalId === 2);

  const caseView2Hospital28 =
    (window.location.hostname === 'view2.ospitek.com' && hospitalId === 28) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 96);

  const caseView2Hospital22 =
    (window.location.hostname === 'localhost' && hospitalId === 84) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 84) ||
    (window.location.hostname === 'view2.ospitek.com' && hospitalId === 22);

  const caseViewHospital55 =
    (window.location.hostname === 'localhost' && hospitalId === 98) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 98) ||
    (window.location.hostname === 'view.ospitek.com' && hospitalId === 55);

  const caseView4Hospital2 =
    (window.location.hostname === 'localhost' && hospitalId === 134) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 134) ||
    (window.location.hostname === 'view4.ospitek.com' && hospitalId === 2);

  const caseView4Hospital3 =
    (window.location.hostname === 'localhost' && hospitalId === 134) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 134) ||
    (window.location.hostname === 'view4.ospitek.com' && hospitalId === 3);

  const corrected1 = {
    ...instructionTemplateLabels,
    [InstructionTemplates.CovidTestSurgeryInstructions]: caseView2Hospital28
      ? 'Locals only'
      : instructionTemplateLabels[InstructionTemplates.CovidTestSurgeryInstructions],
  };

  const corrected2 = caseView2Hospital15
    ? instructionTemplateLabelsView2Hospital15
    : caseViewHospital2
    ? instructionTemplateLabelsViewHospital2
    : caseView2Hospital22
    ? instructionTemplateLabelsView2Hospital22
    : caseViewHospital55
    ? instructionTemplateLabelsViewHospital55
    : caseView4Hospital2 || caseView4Hospital3
    ? { ...instructionTemplateLabelsView4OCOM, DayOfSurgeryInstructions: 'Day of Surgery Instructions' }
    : corrected1;

  const { data, loading } = useQuery(getSurgeryInstructionsTemplates);
  const templates = data?.surgeryInstructionsTemplates || [];

  return (
    <>
      {!loading ? (
        templates.map((template: any, index: number) => (
          <Fragment key={index}>
            <H3 style={{ marginTop: '2em' }}>{corrected2[template.type]}</H3>
            <InstructionsForm
              value={JSON.parse(template?.content || '')?.content}
              refetchQueries={[{ query: getSurgeryInstructionsTemplates }]}
              mutation={updateSurgeryInstructionsTemplate}
              templateType={template.type}
              isTemplate={true}
            />
          </Fragment>
        ))
      ) : (
        <CenteredSpinner size={undefined} strokeAccessor={undefined} />
      )}
    </>
  );
};
